import React, { useState } from "react";
import { HorizontalOverflow } from "../HorizontalOverflow";
import {
  ButtonList,
  ButtonListItem,
  ButtonItem
} from "../ActionAndDateBusinessValueCodesFilter/styles";

type Item = {
  id: string;
  name: string;
};

type Props = {
  initialSelected: string;
  inputProps?: Record<string, any>;
  items: Item[];
  itemToString?: (item: Item) => string;
  label?: string;
  onChange: (item: Item) => void;
};

export function SegmentButton({
  itemToString = (item: Item) => item && item.name,
  onChange,
  initialSelected,
  ...props
}: Props) {
  const [selected, setSelected] = useState<string>(initialSelected);
  const { items } = props;
  return items.length ? (
    <HorizontalOverflow>
      <ButtonList {...props}>
        {items.map(item => {
          const isSelected = selected === item.id;
          return (
            <ButtonListItem key={item.id}>
              <ButtonItem
                key={item.id}
                selected={isSelected}
                aria-pressed={isSelected}
                onClick={() => {
                  onChange(item);
                  setSelected(item.id);
                }}
              >
                {itemToString ? itemToString(item) : String(item)}
              </ButtonItem>
            </ButtonListItem>
          );
        })}
      </ButtonList>
    </HorizontalOverflow>
  ) : null;
}
