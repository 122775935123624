import React from "react";
import { useIntl, FormattedRelativeTime, IntlShape } from "react-intl";
import isSameYear from "date-fns/isSameYear";
import isSameDay from "date-fns/isSameDay";
import differenceInSeconds from "date-fns/differenceInSeconds";
import styled from "styled-components";

type DateInput = string | number | Date;
type TimeZoneName = "short" | "long";
type Props = {
  value: DateInput | null | undefined;
  /** threshold defines how many hours old before it should not show relative time */
  threshold?: number;
  detailed?: boolean;
  showTimeZone?: boolean;
};

export function formatDateTime(
  intl: IntlShape,
  date: DateInput,
  timeZoneName?: TimeZoneName | undefined
): string {
  return intl.formatDate(date, {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hourCycle: "h23",
    timeZoneName
  });
}

export function formatShortTime(
  intl: IntlShape,
  date: DateInput,
  timeZoneName?: TimeZoneName,
  detailed?: boolean
): string {
  return intl.formatTime(date, {
    hour: "2-digit",
    minute: "2-digit",
    second: detailed ? "2-digit" : undefined,
    hourCycle: "h23",
    timeZoneName
  });
}

export function formatShortDateTime(
  intl: IntlShape,
  date: DateInput,
  timeZoneName?: TimeZoneName,
  detailed?: boolean,
  showYear?: boolean
): string {
  const useDetailed = detailed ?? false;
  const useShowYear = showYear ?? false;

  return intl.formatDate(date, {
    year: useShowYear ? "numeric" : undefined,
    month: "short",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: useDetailed ? "2-digit" : undefined,
    hourCycle: "h23",
    timeZoneName
  });
}

const ONE_HOUR = 60 * 60 * 1000; /* ms */

const StyledTime = styled.time`
  white-space: pre;
`;

export default function Time({
  value,
  showTimeZone,
  detailed,
  threshold = 1
}: Props) {
  const now = React.useRef(new Date());
  const intl = useIntl();

  if (!value) {
    return null;
  }

  const date = new Date(value);
  const isOlderThanThreshold =
    Number(now.current) - Number(date) > ONE_HOUR * threshold;
  const isToday = isSameDay(now.current, date);
  const showYear = !isSameYear(now.current, date);
  let timeZoneName;

  if (!isOlderThanThreshold || showTimeZone) {
    timeZoneName = "short";
  }

  const formattedDateTime = formatDateTime(intl, value, "short");
  const formattedShortTime = formatShortTime(
    intl,
    value,
    timeZoneName,
    detailed
  );
  const formattedShortDateTime = formatShortDateTime(
    intl,
    value,
    timeZoneName,
    detailed,
    showYear
  );

  return (
    <StyledTime dateTime={String(value)} title={formattedDateTime}>
      {isOlderThanThreshold ? (
        isToday ? (
          formattedShortTime
        ) : (
          formattedShortDateTime
        )
      ) : (
        <FormattedRelativeTime
          value={differenceInSeconds(date, now.current)}
          numeric="auto"
          updateIntervalInSeconds={1}
        />
      )}
    </StyledTime>
  );
}
