import styled, { css } from "styled-components";
import { Box, Button } from "@urbaninfrastructure/react-ui-kit";

const buttonListCss = () => css`
  list-style: none;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: stretch;
  justify-content: flex-start;
  padding: 0;
`;

const buttonListItemCss = () => css`
  margin: 0;
  padding: 0;
  display: flex;
  align-items: stretch;
  button {
    border-left-width: 1px;
    border-radius: 0px;
    text-transform: uppercase;
    font-weight: bold;
    padding: ${({ theme }) => theme.space[2]}px
      ${({ theme }) => theme.space[3]}px;
    white-space: nowrap;
    &:hover,
    &:focus {
      border-color: ${({ theme }) => theme.colors.primary};
    }
    &:focus {
      z-index: 1;
    }
  }
  &:first-child {
    button {
      border-top-left-radius: ${({ theme }) => theme.radii["md"]};
      border-bottom-left-radius: ${({ theme }) => theme.radii["md"]};
      border-left-width: 2px;
      margin-left: 0;
    }
  }
  &:last-child {
    button {
      border-top-right-radius: ${({ theme }) => theme.radii["md"]};
      border-bottom-right-radius: ${({ theme }) => theme.radii["md"]};
      border-right-color: ${({ theme }) => theme.colors.primary};
      &:hover,
      &:focus {
        border-right-color: ${({ theme }) => theme.colors.primary};
      }
    }
  }
`;

const buttonItemCss = ({ theme, selected }) => css`
  border-color: ${theme.colors.primary};
  font-size: ${theme.fontSizes[1]};
  ${selected &&
    css`
      background: ${theme.colors.primary};
      color: ${theme.colors.white};
      &:focus {
        color: ${theme.colors.white};
      }
    `};
`;
const dateFilterBoxCss = () => css`
  display: flex;
  flex-direction: row;
  @media screen and (max-width: 755px) {
    flex-direction: column;
  }
  @media screen and (max-width: 590px) {
    flex-direction: row;
  }
`;

const responsiveMobileWrapperCss = () => css`
  display: none;
  @media screen and (max-width: 800px) {
    display: block;
  }
`;

const responsiveDesktopWrapperCss = () => css`
  display: block;
  @media screen and (max-width: 800px) {
    display: none;
  }
`;

export const ButtonList = styled.ul`
  ${buttonListCss};
`;

export const ButtonListItem = styled.li`
  ${buttonListItemCss};
`;

export const ButtonItem = styled(Button)`
  ${buttonItemCss};
`;

export const DateFilterBox = styled(Box)`
  ${dateFilterBoxCss};
`;

export const ResponsiveMobileWrapper = styled(Box)`
  ${responsiveMobileWrapperCss};
`;

export const ResponsiveDesktopWrapper = styled(Box)`
  ${responsiveDesktopWrapperCss};
`;
